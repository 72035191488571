import i18nData from '../../i18n/i18nData.js'
import AXIOS from '../../http/axios/index.js'
import {sendRequest} from '../../http/axios/requestHelper.js'

var common = {
  getIndustries (vueJs) {
    return [
      {
        text: i18nData[vueJs.$i18n.locale]['Mixed'],
        value: 'mixed'
      },
      {
        text: i18nData[vueJs.$i18n.locale]['Coffee, Beans, Oil, Honey & Herbs'],
        value: 'coffee_beans_oil_honey_herbs'
      },
      {
        text: i18nData[vueJs.$i18n.locale]['Accessories'],
        value: 'accessories'
      },
      {
        text: i18nData[vueJs.$i18n.locale]['Mobile & Laptop Accessories'],
        value: 'mobile_laptop_accessories'
      },
      {
        text: i18nData[vueJs.$i18n.locale]['Home Accessories'],
        value: 'home_accessories'
      },
      {
        text: i18nData[vueJs.$i18n.locale]['Apparel, Fashion and Clothes'],
        value: 'apparel_fashion_and_clothes'
      },
      {
        text: i18nData[vueJs.$i18n.locale]['Pharmaceutical Medicine, and supplements'],
        value: 'pharmaceutical_medicine_and_supplements'
      },
      {
        text: i18nData[vueJs.$i18n.locale]['Beauty Cosmetics, Perfumes & Personal Care'],
        value: 'beauty_cosmetics_perfumes_personal_care'
      },
      {
        text: i18nData[vueJs.$i18n.locale]['Detergent and Cleaners'],
        value: 'detergent_cleaners'
      },
      {
        text: i18nData[vueJs.$i18n.locale]['Jewelry & Expensive Collectibles'],
        value: 'jewelry_expensive_collectibles'
      },
      {
        text: i18nData[vueJs.$i18n.locale]['Car & Motorcycle Accessories'],
        value: 'car_motorcycle_accessories'
      },
      {
        text: i18nData[vueJs.$i18n.locale]['Electronics'],
        value: 'electronics'
      },
      {
        text: i18nData[vueJs.$i18n.locale]['Gifts & Printed Materials'],
        value: 'gifts_printed_materials'
      },        {
        text: i18nData[vueJs.$i18n.locale]['Eyeglasses and Contact Lenses'],
        value: 'eyeglasses_contact_lenses'
      },
      {
        text: i18nData[vueJs.$i18n.locale]['Vending, Coffee & Food Machines'],
        value: 'vending_coffee_food_machines'
      },
      {
        text: i18nData[vueJs.$i18n.locale]['Books'],
        value: 'books'
      },
      {
        text: i18nData[vueJs.$i18n.locale]['Pet Supplies & Accessories'],
        value: 'pet_supplies_accessories'
      },
      {
        text: i18nData[vueJs.$i18n.locale]['Bags, Packs, Bottles & Flasks'],
        value: 'bags_packs_bottles_flasks'
      },
      {
        text: i18nData[vueJs.$i18n.locale]['Documents'],
        value: 'documents'
      },
      {
        text: i18nData[vueJs.$i18n.locale]['Handmade Products'],
        value: 'handmade_products'
      }
    ]
  },
  salesChannels: [
    'Facebook',
    'Instagram',
    'TikTok',
    'eCommerce Website - Shopify',
    'eCommerce Website - WooCommerce',
    'eCommerce Website - Native',
    'eCommerce Website - Magento',
    'Marketplace',
    'Affiliate Platform',
    'Other'
  ],
  notifyResponseError (vueJs, error) {
    if (error.response) {
      if (error.response.status === 403 && error.response.data.message) {
        vueJs.$vs.notify({
          color:'danger',
          title:i18nData[vueJs.$i18n.locale]['Failed'],
          text: error.response.data.message,
          position: 'top-center'
        })
      } else if (error.response.status === 403) {
        vueJs.$vs.notify({
          color:'danger',
          title:i18nData[vueJs.$i18n.locale]['Failed'],
          text: error.response.data.detail,
          position: 'top-center'
        })
      } else if (error.response.status === 401 && error.response.data.code === 'token_not_valid') {
        vueJs.$vs.notify({
          color:'danger',
          title:i18nData[vueJs.$i18n.locale]['Failed'],
          text: 'Session expired. Please, refresh the page!',
          position: 'top-center'
        })
      } else if (error.response.status === 401) {
        vueJs.$vs.notify({
          color:'danger',
          title:i18nData[vueJs.$i18n.locale]['Failed'],
          text: error.response.data.detail,
          position: 'top-center'
        })
      } else if ((error.response.status === 400 || error.response.status === 404) && error.response.data.message) {
        vueJs.$vs.notify({
          color:'danger',
          title:i18nData[vueJs.$i18n.locale]['Failed'],
          text: error.response.data.message,
          position: 'top-center'
        })
      } else if (error.response.status === 404) {
        vueJs.$vs.notify({
          color:'danger',
          title:i18nData[vueJs.$i18n.locale]['Failed'],
          text: error.response.message ? error.response.message : i18nData[vueJs.$i18n.locale]['Not found!'],
          position: 'top-center'
        })
      } else if (error.response.status === 400) {
        let key = 1
        let keyItem = 1
        if (error.response.data[0] && error.response.data[0].non_field_errors) {
          for (key in error.response.data[0].non_field_errors) {
            if (error.response.data[0].non_field_errors.hasOwnProperty(key)) {
              vueJs.$vs.notify({
                color:'danger',
                title:i18nData[vueJs.$i18n.locale]['Failed'],
                text: `${error.response.data[0].non_field_errors[key]}`,
                position: 'top-center',
                showClose: true
              })
              key++
            }
          }
        } else if (error.response.data) {
          for (key in error.response.data) {
            if (typeof (error.response.data[key]) === 'object') {
              for (keyItem in error.response.data[key]) {
                vueJs.$vs.notify({
                  color:'danger',
                  title:i18nData[vueJs.$i18n.locale]['Failed'],
                  text: `${typeof (error.response.data[key][keyItem]) === 'object' ?keyItem : ''} ${error.response.data[key][keyItem]}`,
                  position: 'top-center',
                  showClose: true
                })
              }
            } else {
              if (error.response.data.hasOwnProperty(key)) {
                vueJs.$vs.notify({
                  color:'danger',
                  title:i18nData[vueJs.$i18n.locale]['Failed'],
                  text: `${error.response.data[key]}`,
                  position: 'top-center',
                  showClose: true
                })
              }
            }
          }
        } else {
          for (key in error.response.data) {
            if (error.response.data.hasOwnProperty(key)) {
              vueJs.$vs.notify({
                color:'danger',
                title:i18nData[vueJs.$i18n.locale]['Failed'],
                text: `${key}: ${error.response.data[key]}`,
                position: 'top-center',
                showClose: true
              })
              key++
            }
          }
        }
      } else {
        vueJs.$vs.notify({
          color:'danger',
          title:i18nData[vueJs.$i18n.locale]['Failed'],
          text:i18nData[vueJs.$i18n.locale]['Error. Please try again!'],
          position: 'top-center'
        })
      }
    }
  },
  getOrderStatusColor (status) {
    status = status ? (status.value ? status.value.replace(/ /g, '_') : status.replace(/ /g, '_')) : status
    if (status && status.startsWith('delivery_attempted')) return '#E6194B'
    switch (status) {
    case 'cancelled':
      return '#020202'
    case 'created':
      return '#1E1E1E'
    case 'pickup_requested':
      return '#1C5BFE'
    case 'return_requested':
      return '#FFC8A1'
    case 'collection_requested':
      return '#FFC8A1'
    case 'picked_up':
      return '#000075'
    case 'in_transit':
      return '#808000'
    case 'en_route':
      return '#800000'
    case 'out_for_delivery':
      return '#C7B060'
    case 'out_for_return':
      return '#C7B060'
    case 'rto_requested':
      return '#9A6324'
    case 'returned_to_origin':
      return '#17814C'
    case 'delivered':
      return '#17814C'
    case 'returned':
      return '#65DCA1'
    case 'collected':
      return '#17814C'
    case 'failed':
      return '#E6194B'
    case 'collection_attempted':
      return '#E6194B'
    case 'return_attempted':
      return '#E6194B'
    case 'return_postponed':
      return '#E6194B'
    case 'processing':
      return '#E76910'
    case 'ready':
      return '#65DCA1'
    case 'payment_due':
      return '#E6194B'
    case 'fully_paid':
      return '#17814C'
    case 'pending':
      return '#E76910'
    case 'approved':
      return '#17814C'
    case 'in_progress':
      return '#E76910'
    case 'completed':
      return '#17814C'
    case 'sealed':
      return 'primary'
    case 'dropped_off':
      return '#17814C'
    case 'declined':
      return '#E6194B'
    case 'rescheduled':
      return '#42D4F4'
    case 'pickup_rescheduled':
      return '#E76910'
    case 'pickup_postponed':
      return '##6278B1'
    case 'missing_packages':
      return '#E6194B'
    case 'missing_input':
      return '#F032E6'
    case 'paid':
      return '#17814C'
    case 'requested':
      return '#E76910'
    case 'out_for_pickup':
      return '#C7B060'
    case 'added':
      return 'dark'
    case 'delivery_order_status=created,pickup_requested,out_for_pickup&return_order_status=created,return_requested':
      return '#000000'
    case 'delivery_order_status=picked_up,in_transit,en_route,out_for_delivery,delivery_attempted&return_order_status=created,return_requested,return_attempted':
      return '#0A3266'
    case 'delivery_order_status=delivered&return_order_status=picked_up,in_transit,en_route,return_postponed,return_refused':
      return '#C7B060'
    case 'delivery_order_status=delivered&return_order_status=returned':
      return '#31A9B0'
    case 'Exchanged':
      return '#31A9B0'
    case 'delivery_order_status=cancelled,delivery_attempted&return_order_status=cancelled,return_refused':
      return '#D21C1C'
    case 'picked_up_to_exchange':
      return '#0A3266'
    case 'Swapping':
      return '#C7B060'
    case 'failed_to_exchange':
      return '#D21C1C'
    case 'queued':
      return '#C7B060'
    case 'success':
      return '#17814C'
    case 'fail':
      return '#E6194B'
    case 'received':
      return '#808000'
    case 'inbounded':
      return '#C7B060'
    case 'confirmed_to_ship': 
      return '#000075'
    case 'picking': 
      return '#c7b060'
    case 'packing': 
      return '#808000'
    case 'shipped': 
      return '#17814C'
    case 'failed_to_fulfill': 
      return '#E6194B'
    case 'failed_to_receive': 
      return '#E6194B'
    case 'resolved': 
      return '#17814C'
    case 'successful': 
      return '#17814C'
    case 'active': 
      return '#17814C'
    case 'on_hold': 
      return '#FF9F43'
    case 'receiving_in_progress':
      return '#FF9F43'
    case 'ready,received,inbounded':
      return '#FF9F43'
    case 'draft':
      return '#1E1E1E'
    case 'submitted':
      return '#6c757d'
    case 'stored':
      return '#17814C'
    case 'Fully Received':
      return '#17814C'
    case 'fulfillment':
      return '#F58231'
    case 'failed':
      return '#E6194B'
    case 'sent':
      return '#17814C'
    case 'attempted':
      return '#E6194B'
    case 'expired':
      return '#E6194B'
    case 'issued':
      return '#1E1E1E'
    case 'reviewed':
      return '#65DCA1'
    case 'rejected': 
      return '#E6194B'
    case 'deposited': 
      return '#17814C'
    case 'pickups':
      return '#000000'
    case 'returns':
      return '#FF9F43'
    case 'rto':
      return '#FF9F43'
    case 'lost':
      return '#E6194B'
    case 'damaged':
      return '#E6194B'
    case 'forfeited':
      return '#E6194B'
    case 'recalled':
      return '#17814C'
    default: 
      return '#1C5BFE'
    }
  },
  getStatusLabelForMerchant( order ) {
    const inNetworkStatuses = ['in_transit', 'en_route']
    const fullfilledStatuses = ['delivered', 'returned', 'returned_to_origin', 'collected']
    const fulfillemntStockStatuses = ['ready', 'received', 'inbounded']
    if (fullfilledStatuses.includes(order.status)) {
      return order.status
    } else if (order.rto_requested) {
      return 'RTO Requested'
    } else if (new Date(order.preferred_date) > new Date(order.estimated_date) && inNetworkStatuses.includes(order.status)) {
      return `Rescheduled ${order.preferred_date}`
    } else if (Number(order.delivery_attempts) > 0 && inNetworkStatuses.includes(order.status)) {
      return `Delivery Attempted: ${order.delivery_attempts}`
    } else if ((order.stock_request || order.num_vehicles) && fulfillemntStockStatuses.includes(order.status)) {
      return `Receiving in Progress`
    } else if ((order.stock_request || order.num_vehicles) && order.status === 'stored') {
      return `Fully Received`
    } else {
      return order.status ? order.status.replace(/_/g, ' ') : ''
    }
  },
  getStatusLabelForOperations (order) {
    if (order.status === 'in_transit' && order.current_warehouse && order.current_warehouse.code) {
      return `In Transit (${order.current_warehouse.code})`
    } else {
      return order.status ? (order.status.value ? order.status.value.replace(/_/g, ' ') : order.status.replace(/_/g, ' ')) : ''
    }
  },
  getStatusLabel (order, userRole) {
    if (userRole === 'merchant') {
      return this.getStatusLabelForMerchant(order)
    } else {
      return this.getStatusLabelForOperations(order)
    }
  },
  getExchangeStatusLabel (exchangeOrder) {
    let status = ''
    const deliveryOrder = exchangeOrder.delivery_order
    const returnOrder = exchangeOrder.return_order
    if (
      (deliveryOrder.status === 'created' || deliveryOrder.status === 'pickup_requested' || 
      deliveryOrder.status === 'out_for_pickup') &&
      (returnOrder.status === 'created' || returnOrder.status === 'return_requested')) {
      status = 'created'
    } else if (
      (deliveryOrder.status === 'picked_up' || deliveryOrder.status === 'in_transit' || 
      deliveryOrder.status === 'en_route' || deliveryOrder.status === 'out_for_delivery' || 
      deliveryOrder.status === 'delivery_attempted') &&
      (returnOrder.status === 'created' || returnOrder.status === 'return_requested' || 
      returnOrder.status === 'return_attempted')) {
      status = 'picked_up_to_exchange'
    } else if (
      (deliveryOrder.status === 'delivered') &&
      (returnOrder.status === 'picked_up' || returnOrder.status === 'in_transit' || 
      returnOrder.status === 'en_route' || returnOrder.status === 'return_postponed' || 
      returnOrder.status === 'return_refused')) {
      status = 'Swapping'
    } else if (deliveryOrder.status === 'delivered' && returnOrder.status === 'returned') {
      status = 'Exchanged'
    } else if ((deliveryOrder.status === 'cancelled' || deliveryOrder.status === 'delivery_attempted') && 
      (returnOrder.status === 'cancelled' || returnOrder.status === 'return_refused')) {
      status = 'failed_to_exchange'
    }
    return status ? status.replace(/_/g, ' ') : ''
  },
  gettingDate (date) {
    const locale = (localStorage.getItem('locale') && localStorage.getItem('locale') === 'en') ? 'en-US' : 'ar-EG'
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const formateDate = new Date(date).toLocaleDateString(locale, options)
    return formateDate
  },
  checkTooltip (order) {
    return Number(order.delivery_attempts) || Number(order.return_attempts) > 0
  },
  formatDate (date) {
    const d = new Date(date)
    let month = d.getMonth() + 1
    let day = d.getDate()
    const year = d.getFullYear()

    if (month.length < 2) month = `0${month}`
    if (day.length < 2) day = `0${day}`
    return [year, month, day].join('-')
  },
  manageSearch (searchVal, loadData, interval, digits) {
    const length = digits ? digits : 3
    if ((searchVal && searchVal.target && searchVal.target.value && searchVal.target.value.length >= length) || (searchVal && searchVal.length >= length)) {
      return searchVal && searchVal.target ? loadData() : setTimeout(() => { loadData() }, interval ? interval : 3000)
    } else if (searchVal && searchVal.length === 0) {
      return loadData()
    }
  },
  loadWarehouses (formatWarehouses, vueJs) {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
    AXIOS.get('api/v1/warehouses/?limit=100', {headers: headers}, {
    })
      .then((response) => {
        const data = response.data.results
        const time = new Date()
        const warehousesDataTime = {data, time}
        localStorage.setItem('warehouses', JSON.stringify(warehousesDataTime))
        return formatWarehouses(response.data.results)
      })
      .catch((error) => {
        return common.notifyResponseError(vueJs, error)
      })
  },
  manageLoadWarehouses (formatWarehouses, vueJs) {
    if (localStorage.getItem('warehouses') && (new Date() - new Date(JSON.parse(localStorage.getItem('warehouses')).time)) / (24 * 60 * 60 * 1000) <= 1) {
      return formatWarehouses(JSON.parse(localStorage.getItem('warehouses')).data)
    } else {
      common.loadWarehouses(formatWarehouses, vueJs)
    }
  },
  splitYearOfDate (date) {
    return new Date(date).toLocaleDateString('fr-CA', {month: '2-digit',day: '2-digit'})      
  },
  loadGovernorates (vue) {
    return new Promise((resolve, reject) => {
      sendRequest(false, false, vue, 'api/v1/governorates/', 'get', null, true, 
        (response) => {
          let governorates = response.data.filter(item => item.id !== 11 && item.id !== 12 && item.id !== 20 && item.id !== 21 && item.id !== 22 && item.id !== 23)
          resolve({
            data: governorates
          })
        }
      )
    })
  },
  loadCities (governorateID, vue) {
    return new Promise((resolve, reject) => {
      sendRequest(false, false, vue, `api/v1/governorates/${governorateID}/cities/`, 'get', null, true, 
        (response) => {
          const cities = response.data.filter(item => item.id !== 91)
          resolve({
            data: cities
          })
        }
      )
    })
  },
  loadZones (cityId, vue) {
    return new Promise((resolve, reject) => {
      sendRequest(false, false, vue, `api/v1/cities/${cityId}/zones/`, 'get', null, true, 
        (response) => {
          const zones = response.data.filter(item => item.id !== 284)
          resolve({
            data: zones
          })
        }
      )
    })
  },
  async checkMerchantInLocalStorage (vueJs) {
    let userMerchant = {}
    let user = {}
    if (JSON.parse(localStorage.getItem('userMerchant')) === null || JSON.parse(localStorage.getItem('merchant') === null)) {
      return new Promise((resolve, reject) => {
        sendRequest(false, false, vueJs, `api/v1/users/merchants/${vueJs.$store.state.AppActiveUser.uid}/`, 'get', null, true,
        async (response) => {
          localStorage.setItem('userMerchant', JSON.stringify(response.data))
          await sendRequest(false, false, vueJs, `api/v1/merchants/${response.data.merchant}/`, 'get', null, true,
            (results) => {
              localStorage.setItem('merchant', JSON.stringify(results.data))
              const merchantObj = {
                merchantData: results.data,
                userMerchantData: response.data
              }
              resolve(merchantObj)
            }
          )
        })
      })
    }
    if (JSON.parse(localStorage.getItem('merchant') !== null) && JSON.parse(localStorage.getItem('userMerchant')) !== null) {
      userMerchant = JSON.parse(localStorage.getItem('userMerchant'))
      user = JSON.parse(localStorage.getItem('merchant'))
      const merchantObj = {
        userMerchantData: userMerchant,
        merchantData: user
      }
      return merchantObj
    }
  },
  isIOS () {
    const browserInfo = navigator.userAgent.toLowerCase()
    if (browserInfo.match('iphone') || browserInfo.match('ipad')) {
      return true
    }
    if (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod', 'Mac', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'].includes(navigator.platform)) {
      return true
    }
    if (navigator.userAgent.indexOf('Mac OS X') != -1) {
      return true
    }
    return false
  },
  openNotificationFailureData (vueJs, order) {
    if (order.return_attempts > 0 || order.delivery_attempts > 0) {
      sendRequest(true, false, vueJs, `api/v1/${order.tracking_number[0] === '1' || (order.tracking_number[0] === '3' && order.tracking_number[1] === '1') ? 'delivery-orders' : 'returns'}/${order.id}/`, 'get', null, true, 
        (response) => {
          const failureData = response.data.tracking_events.filter(item => item.comment).map(element => `${new Date(element.created).toLocaleDateString('fr-CA')} ${i18nData[vueJs.$i18n.locale][element.comment.split(' -')[0]] ? i18nData[vueJs.$i18n.locale][element.comment.split(' -')[0]] : element.comment.split(' -')[0]}`).join('<br>')
          vueJs.$vs.notify({
            title: i18nData[vueJs.$i18n.locale]['Failure Reasons'],
            text: failureData,
            position: vueJs.$vs.rtl ? 'top-left' : 'top-right',
            color: 'secondary'
          })
        })
    } else {
      vueJs.$vs.notify({
        title: i18nData[vueJs.$i18n.locale]['Failure Reasons'],
        text: i18nData[vueJs.$i18n.locale]['No attempts!'],
        position: vueJs.$vs.rtl ? 'top-left' : 'top-right',
        color: 'secondary'
      })
    }
  }
}
export default common
