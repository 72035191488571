import axios from 'axios'
import i18nData from '../../i18n/i18nData.js'
import common  from '@/assets/utils/common'

const request = (tableLoader, url, cache, VueJs, endpoint, requestType, data, authenticated, successCallback = null, errorCallback = null) => {
  if (cache && !VueJs.isOnline && requestType !== 'get') {
    const api_cache = JSON.parse(localStorage.getItem('APIs-cache'))
    api_cache.push({
      url,
      method: requestType,
      body: data
    })
    VueJs.$vs.notify({
      color: 'warning',
      title: i18nData[VueJs.$i18n.locale]['Warning'],
      text: i18nData[VueJs.$i18n.locale]['Data stored in your device and will be synced when you reconnect online!'],
      position: 'top-center'
    })
    localStorage.setItem('APIs-cache', JSON.stringify(api_cache))
  } else if (!cache && !VueJs.isOnline && requestType !== 'get') {
    VueJs.$vs.notify({
      color: 'warning',
      title: i18nData[VueJs.$i18n.locale]['Warning'],
      text: 'Sorry, should be online to perform this operation!',
      position: 'top-center'
    })
  } else {
    if (!tableLoader) {
      VueJs.$vs.loading()
    }
    let headers = null
    if (authenticated) {
      headers = {
        headers: {
          'Accept-Language': VueJs.$i18n.locale,
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      }
    }
    
    const requestObject = {
      get: axios.get,
      post: axios.post,
      delete: axios.delete,
      put: axios.put,
      patch: axios.patch
    }


    let request = null
    if (data) {
      request = requestObject[requestType](url, data, headers)
    } else {
      request = requestObject[requestType](url, headers)
    }
    request
      .then(response => {
        if (successCallback) {
          successCallback(response)
          VueJs.$vs.loading.close()
          VueJs.$validator.pause()
          setTimeout(()  => {
            VueJs.$validator.resume()
          }, 100)
        }
      })
      .catch(error => {
        if (!errorCallback) {
          common.notifyResponseError(VueJs, error)
          VueJs.$vs.loading.close()
        } else {
          errorCallback(error)
        }
      })
  }
}

const sendFulfillmentRequest = (tableLoader, cache, VueJs, endpoint, requestType, data = null, authenticated, successCallback = null, errorCallback = null) => {
  const url = `${process.env.VUE_APP_FULFILLMENT_URL}/${endpoint}`
  request(tableLoader, url, cache, VueJs, endpoint, requestType, data, authenticated, successCallback, errorCallback)
}
const sendRequest = (tableLoader, cache, VueJs, endpoint, requestType, data = null, authenticated, successCallback = null, errorCallback = null) => {
  const url = `${process.env.VUE_APP_API_URL}/${endpoint}`
  request(tableLoader, url, cache, VueJs, endpoint, requestType, data, authenticated, successCallback, errorCallback)
}
export { sendRequest, sendFulfillmentRequest }
